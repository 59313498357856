import React from 'react';
import cn from 'classnames';
import { useDetectClickOutside } from '@hooks/useDetectClickOutside';

// @ts-ignore
import styles from './Dropdown.module.scss';
import { DropdownList } from './DropdownList';
import { findSelectedOptionIndex, getOptionLabel } from './util';
import type { DropdownComparator, DropdownOption } from './types';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { Flex, Box, Spinner, Icon } from '@chakra-ui/react';

import { useInfiniteScroll, UseInfiniteScrollProps } from '@hooks';

const getNativeOption = (o: DropdownOption) => {
  if (typeof o === 'string') {
    return (
      <option value={o} key={'option-' + o}>
        {o}
      </option>
    );
  }
  return o.nativeOption;
};

export type AsyncDropdownSelection = {
  name: string;
  value: string;
  type: 'dropdown';
};

type AsyncDropdownOption = DropdownOption & {
  displayLabel?: React.ReactNode;
};

export type AsyncDropdownProps = {
  /** Should be unique to the attribute that is aiming to change. Translated to #id for the dropdown list */
  name?: string;
  options: AsyncDropdownOption[];
  selectedOption?: string;
  defaultSelectedIndex?: number;
  active?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  showCaret?: boolean;
  style?: any;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  /** Enable input filtered dropdown list behaviour */
  handleChange?: (el: AsyncDropdownSelection) => void;
  handleDropdownClosed?: () => void;
  /** Custom compare function for the list filtering */
  customComparator?: DropdownComparator;
  labelClassname?: string;
  tertiary?: boolean;
  Select?: 'select' | React.FunctionComponent;

  /** Input props */
  onInputChange?: (value: string) => void;
  searchValue: string;
  inputPlaceholder?: string;
  /**IniniteScroll props */
  infiniteScrollOptions?: Pick<
    UseInfiniteScrollProps,
    'hasDataEnd' | 'isLoading' | 'onLoadMore'
  >;
};

const getDisplayLabel = (option: AsyncDropdownOption, placeholder: string) => {
  if (option?.displayLabel) {
    return option.displayLabel;
  }

  return getOptionLabel(option, placeholder);
};

export function AsyncDropdown({
  name,
  options = [],
  selectedOption,
  defaultSelectedIndex,
  active,
  disabled,
  className,
  showCaret = true,
  style,
  handleChange,
  handleDropdownClosed,
  onInputChange,
  infiniteScrollOptions,
  Select = 'select',
  searchValue,
  isLoading,
  isLoadingMore,
  placeholder,
  inputPlaceholder,
}: AsyncDropdownProps): JSX.Element {
  /**We are using the useState to save the ref of dropdown because we have some race conditions
   * if we use the useRef. Those race conditions are occur because initially the dropdown list is
   * hidden, and the useRef does not trigger the change detection. This makes the infinite scroll to
   * have the `target` as undefined and it never works.
   */
  const [dropdownRef, setDropdownRef] = React.useState(null);
  const [indicatorsRef, setIndicatorsRef] = React.useState(null);
  const selectionRef = React.useRef<HTMLLIElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const nativeOptions = React.useMemo(
    () => options.map(getNativeOption).filter(o => o),
    [options],
  );

  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    if (defaultSelectedIndex != null && defaultSelectedIndex >= 0) {
      return defaultSelectedIndex;
    }

    return options.findIndex(option => {
      const preSelectedOption =
        typeof option === 'string' ? option : option.value;
      return preSelectedOption === selectedOption;
    });
  });

  const [isActive, setIsActive] = useDetectClickOutside(null, !!active, null, [
    indicatorsRef,
    dropdownRef,
    searchInputRef.current,
  ]);
  const [isSearchInputActive, setSearchInputActive] = React.useState(false);

  const displayLabel = getDisplayLabel(options[selectedIndex], placeholder);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.currentTarget.value;
    onInputChange?.(newSearchValue);
  };

  const filteredOptions = options;

  useInfiniteScroll({
    target: dropdownRef,
    onLoadMore: infiniteScrollOptions?.onLoadMore,
    isLoading: isLoadingMore,
    hasDataEnd: infiniteScrollOptions?.hasDataEnd,
  });

  const resetSelection = () => {
    setSelectedIndex(-1);
    handleChange({
      name,
      value: '',
      type: 'dropdown',
    });
  };

  React.useEffect(() => {
    if (isActive || !handleDropdownClosed) {
      return;
    }
    handleDropdownClosed();
  }, [isActive]);

  React.useEffect(() => {
    if (
      name &&
      selectedIndex != null &&
      selectedIndex >= 0 &&
      options.length > 0 &&
      typeof handleChange === 'function'
    ) {
      const selectedOption = options[selectedIndex];
      const value =
        typeof selectedOption === 'string'
          ? selectedOption
          : selectedOption.value;

      handleChange({
        name,
        value,
        type: 'dropdown',
      });
    }
  }, [selectedIndex]);

  React.useLayoutEffect(() => {
    if (
      !isActive ||
      !selectionRef.current?.scrollIntoView ||
      selectedIndex == undefined
    ) {
      return;
    }
    selectionRef.current.scrollIntoView({ block: 'nearest' });
  }, [isActive, selectedIndex, searchValue]);

  const onKeyUpInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if ((e.target as HTMLInputElement).value === '') {
        resetSelection();
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setIsActive(false);
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (isActive) {
        setSelectedIndex((i = 0) =>
          i - 1 < 0 || i - 1 > filteredOptions.length
            ? filteredOptions.length - 1
            : i - 1,
        );
      } else {
        setIsActive(true);
      }
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (isActive) {
        setSelectedIndex(i => ((i || 0) + 1) % filteredOptions.length);
      } else {
        setIsActive(true);
      }
      return;
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      searchInputRef.current?.blur();
      setSearchInputActive(false);
      e.preventDefault();
      setIsActive(!isActive);
    }
  };

  const handleListSelection = (selectedOption: DropdownOption) => {
    searchInputRef.current?.blur();
    const selectedIndex = findSelectedOptionIndex(options, selectedOption);
    setSearchInputActive(false);
    setSelectedIndex(selectedIndex);
    setIsActive(false);
  };

  const onSearchInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setSearchInputActive(true);
    searchInputRef.current?.focus();
    e.preventDefault();
    e.stopPropagation();
    setIsActive(true);
  };

  const onSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSearchInputActive(false);
  };

  return (
    <Flex
      flexDirection='column'
      className={cn(
        styles.container,
        { [styles.disabled]: disabled },
        className,
      )}
      style={style}
      onKeyDown={onKeyDown}
      onBlur={() => isActive && setIsActive(false)}
    >
      <Flex
        width='100%'
        onFocus={onSearchInputFocus}
        tabIndex={0}
        className={cn(styles.trigger)}
        p={3}
        pl={2}
        height={10}
        boxSizing='border-box'
        border='1px solid rgba(0, 0, 0, 0.16)'
        data-focus={isSearchInputActive ? true : null}
        _focus={{ borderColor: 'primary.500' }}
        borderRadius='md'
      >
        <Flex width='100%'>
          <Flex
            width={
              isSearchInputActive || (selectedIndex < 0 && !isSearchInputActive)
                ? '100%'
                : 0
            }
          >
            <input
              onKeyUp={onKeyUpInput}
              className={cn(styles.searchInput)}
              ref={searchInputRef}
              value={searchValue}
              type='text'
              autoComplete='off'
              aria-haspopup='listbox'
              aria-autocomplete='list'
              aria-controls={name}
              role='combobox'
              aria-expanded={isSearchInputActive}
              tabIndex={0}
              onChange={handleSearchInput}
              onBlur={onSearchInputBlur}
              data-no-focus={true}
              placeholder={selectedIndex < 0 ? inputPlaceholder : ''}
            />
          </Flex>

          {!isSearchInputActive && <Flex>{displayLabel}</Flex>}
        </Flex>

        <Box ref={setIndicatorsRef}>
          {showCaret && !isLoading && !isLoadingMore && (
            <Icon as={ChevronDownIcon} />
          )}
          {(isLoading || isLoadingMore) && (
            <Spinner aria-label='loading more items...' size='sm' />
          )}
        </Box>
      </Flex>

      {isActive && (
        <Box>
          <DropdownList
            //@ts-expect-error
            dropdownRef={setDropdownRef}
            selectionRef={selectionRef}
            options={filteredOptions}
            handleListSelection={handleListSelection}
            selectedIndex={selectedIndex}
            listId={name}
            isLoading={isLoading}
          />
        </Box>
      )}

      {nativeOptions.length > 0 && (
        <Select
          name='native-dropdown-picker'
          onChange={e => setSelectedIndex(e.target.selectedIndex)}
        >
          {nativeOptions}
        </Select>
      )}
    </Flex>
  );
}
