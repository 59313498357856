import React from 'react';

import { CardDualPanel, InputBox } from '@components/common';

import { ConfirmationModal } from '@components/modal';
import {
  Button,
  useDisclosure,
  Flex,
  Text,
  InputGroup,
  Input,
  UnorderedList,
  ListItem,
  InputLeftAddon,
} from '@chakra-ui/react';

import { ExclamationIcon } from '@heroicons/react/solid';
import { useDashboardCRUD, useToast } from '@hooks';

const ChangeDomainBody = ({ newDomain, setDomain }) => {
  return (
    <Flex width='100%' direction='column' gap={4}>
      <Flex direction='column' gap={1}>
        <InputBox m={0} p={0} label='New domain'>
          <InputGroup>
            <InputLeftAddon color='rgba(0, 0, 0, 0.65)'>
              https://
            </InputLeftAddon>

            <Input
              value={newDomain}
              onChange={e => setDomain(e.target.value)}
            />
          </InputGroup>
        </InputBox>
      </Flex>
    </Flex>
  );
};

const WarningMessage = () => {
  return (
    <Flex aria-label='warning message' gap={1} flexDirection='column'>
      <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
        After changing domains, you will need to perform the following actions:
      </Text>
      <UnorderedList>
        <ListItem>
          <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
            Update the publishable key
          </Text>
        </ListItem>
        <ListItem>
          <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
            Update DNS records Generate new SSL certificates
          </Text>
        </ListItem>
        <ListItem>
          <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
            If using social connections, update redirect URLs for enabled social
            connections
          </Text>
        </ListItem>
        <ListItem>
          <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
            If using JWT templates, update JWT issuer and JWKS endpoint in
            external JWT SSO services
          </Text>
        </ListItem>
      </UnorderedList>
      <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
        Changing domains will invalidate active sessions, and{' '}
        <Text as='span' textStyle='sm-normal' fontWeight='bold'>
          all users will be signed out.
        </Text>
      </Text>
      <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
        <Text as='span' textStyle='sm-normal' fontWeight='bold'>
          Downtime will occur.{' '}
        </Text>
        We recommend you inform your users and only perform this action when it
        will cause minimal impact.
      </Text>
    </Flex>
  );
};

const ChangeDomain = ({
  domain,
  instanceId,
  applicationName,
  deployedOn,
  onDomainChange,
}: {
  applicationName: string;
  domain: string;
  instanceId: string;
  deployedOn: string;
  onDomainChange: () => void;
}): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToast();
  const [newDomain, setNewDomain] = React.useState('');
  const { create } = useDashboardCRUD();

  async function handleChangeDomain() {
    try {
      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: `https://${newDomain}`,
      });
      showSuccessToast(`Domain has been updated to https://${newDomain}`);
      onDomainChange();
      onClose();
    } catch (err) {
      showErrorToast('Could not update domain');
    }
  }

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Change application domain'
      subtitle={`Currently deployed on ${deployedOn}`}
    >
      <Button
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Change domain
      </Button>
      <ConfirmationModal
        verification={{
          stringToMatch: domain,
          label: (
            <Text as='span' textStyle='md-normal'>
              Are you sure you want to change the{' '}
              <Text as='span' textStyle='md-semibold'>
                {domain}{' '}
              </Text>
              domain of the{' '}
              <Text as='span' textStyle='md-semibold'>
                {applicationName}
              </Text>{' '}
              application?
            </Text>
          ),
          placeholder: 'Type the current domain to confirm the change',
        }}
        isOpen={isOpen}
        handleCancelation={onClose}
        handleConfirmation={handleChangeDomain}
        confirmationMessage='Change domain'
        cancelationMessage='Cancel'
        confirmButtonIcon={ExclamationIcon}
        message={
          <ChangeDomainBody newDomain={newDomain} setDomain={setNewDomain} />
        }
        warningMessage={<WarningMessage />}
        headerTitle='Change application domain'
        alertDialogProps={{ width: '30rem' }}
      />
    </CardDualPanel>
  );
};

export default React.memo(ChangeDomain);
