import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useInstance,
  useLocation,
  useToast,
} from '@hooks';
import { HTTPError } from '@utils';
import { CardDualPanel, PageLoader, StickyBottomCTA } from '..';
import { PATH_OPTIONS } from './constants';
import { PathSetting } from './PathSetting';
import {
  filterOptionsThatRequireOrganizations,
  getSingleSessionFieldsOnly,
  isSingleSessionMode,
} from './utils';
import { preventSubmitOnEnter } from '@utils';

import { useFeatureFlags } from '@hooks';
import { ChangeSubdomain } from '@components/dns';
import { Flex } from '@chakra-ui/react';

//There is a possibility that this Page will be deprecated and removed
// If this does not happen we need to refactor it.

const FORM_ID = 'path_options_settings';

export function PathOptions(): JSX.Element {
  const { instanceId } = useLocation();
  const {
    data: displayConfig,
    isValidating: isValidatingDisplayConfig,
    mutate: mutateDisplayConfig,
  } = useDashboardSWR(() => `/v1/instances/${instanceId}/display_config`);
  const { data: userSettings, isValidating: isValidatingUserSettings } =
    useDashboardSWR(`/v1/instances/${instanceId}/user_settings`);
  const { update } = useDashboardCRUD();
  const { showSuccessToast } = useToast();
  const { instance } = useInstance();
  const organizationsEnabled = !!instance?.organization_settings.enabled;

  const formMethods = useForm();
  const { handleSubmit, formState, reset, setError, clearErrors } = formMethods;
  const { isDirty, isSubmitting } = formState;
  const [updateKey, setUpdateKey] = React.useState(new Date());
  const featureFlags = useFeatureFlags();

  const pathSettings = React.useMemo(() => {
    if (!displayConfig || !instance) {
      return null;
    }
    return {
      ...displayConfig,
      development_origin: instance?.active_domain.development_origin,
      default_development_origin: '',
    };
  }, [displayConfig, instance]);

  React.useEffect(() => {
    if (pathSettings) {
      reset({
        ...pathSettings,
      });
    }
  }, [pathSettings, reset]);

  const onSubmitPaths = async paths => {
    try {
      const { development_origin, ...restPaths } = paths;

      clearErrors();

      if (
        featureFlags?.allow_development_origin &&
        instance?.environment_type === 'development'
      ) {
        await update(`/v1/instances/${instanceId}`, {
          development_origin,
        });
      }

      await update(`/v1/instances/${instanceId}/display_config`, {
        ...displayConfig,
        ...restPaths,
      });
      await mutateDisplayConfig();
      reset(paths);
      showSuccessToast('Path settings saved.');
    } catch (err) {
      // TODO: Add validation in RHF using the same algorithm as in the backend
      if (err?.name === 'HTTPError') {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          const param = err?.meta?.param_name;
          const errorMessage = err?.message;
          if (param) {
            setError(param, { type: 'manual', message: errorMessage });
          }
        });
      } else {
        return;
      }
    }
  };

  const availablePathOptions = React.useMemo(() => {
    const pathOptions = PATH_OPTIONS.filter(
      filterOptionsThatRequireOrganizations(organizationsEnabled),
    );
    return isSingleSessionMode(userSettings)
      ? pathOptions.map(getSingleSessionFieldsOnly)
      : pathOptions;
  }, [userSettings]);

  if (
    isValidatingDisplayConfig ||
    isValidatingUserSettings ||
    !displayConfig ||
    !instance
  ) {
    return <PageLoader />;
  }

  return (
    <Flex flexDirection='column' gap={8}>
      <FormProvider {...formMethods}>
        {availablePathOptions
          .filter(({ isVisible }) =>
            typeof isVisible === 'function'
              ? isVisible?.({ ...instance, ...featureFlags })
              : true,
          )
          .map(pathOption => (
            <CardDualPanel key={pathOption.title} mb={8} {...pathOption}>
              <form
                id={FORM_ID}
                onSubmit={handleSubmit(onSubmitPaths)}
                onKeyDown={preventSubmitOnEnter}
              >
                {pathOption.fields.map((field, fieldIdx) => (
                  <PathSetting
                    key={updateKey + field.title}
                    defaultUrl={pathSettings[field.urlKey]}
                    customPath={pathSettings[field.customPathKey]}
                    isLast={fieldIdx === pathOption.fields.length - 1}
                    transform={field?.transform}
                    {...field}
                  />
                ))}
                <StickyBottomCTA
                  formId={FORM_ID}
                  isVisible={isDirty}
                  onReset={() => {
                    reset(displayConfig);
                    setUpdateKey(new Date());
                  }}
                  isSubmitting={isSubmitting}
                />
              </form>
            </CardDualPanel>
          ))}
      </FormProvider>
      {featureFlags.allow_change_subdomain_domain && (
        <ChangeSubdomain
          onSubdomainChange={mutateDisplayConfig}
          deployedOn={instance?.home_origin}
          instanceId={instance?.id}
          domain={instance?.active_domain.name}
        />
      )}
    </Flex>
  );
}
