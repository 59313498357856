import React from 'react';
import { HTTPError } from '@utils';
import { useForm } from 'react-hook-form';
import { Settings } from '@types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Button,
  Text,
  Flex,
  FormControl,
} from '@chakra-ui/react';
import { useDashboardCRUD, useLocation, useToast } from '@hooks';
import { InputBox } from '@components/common';
import { PhoneInput } from '@clerk-ui/components/phoneInput';
import { useRouter } from 'next/router';
import { removeEmptyValuesFromObject } from '@utils/object';

const FORM_ID = 'create_user_form';

type CreateUserProps = {
  isOpen: boolean;
  onClose: () => void;
  userSettings: Settings;
  page: number;
  mutateUsers: () => void;
};

export function CreateUserModal({
  isOpen,
  onClose,
  userSettings,
  page,
  mutateUsers,
}: CreateUserProps): JSX.Element {
  const router = useRouter();
  const { instanceId } = useLocation();
  const [isCreatingUser, setIsCreatingUser] = React.useState(false);
  const [countryCodePrefix, setCountryCodePrefix] = React.useState('');
  const [error, setError] = React.useState(null);
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const { create } = useDashboardCRUD();

  if (!userSettings) {
    return null;
  }

  const {
    email_address,
    first_name,
    last_name,
    phone_number,
    password,
    username,
  } = userSettings.attributes;

  const path = `/v1/instances/${instanceId}/users`;

  const onCloseModal = () => {
    reset();
    onClose();
    setError(null);
  };

  const createUser = async changes => {
    const userData = { ...removeEmptyValuesFromObject(changes) };

    if (userData.email_address || userData.email_address === '') {
      userData['email_address'] = [userData.email_address];
    }

    if (userData.phone_number) {
      userData['phone_number'] = [
        `${countryCodePrefix}${userData.phone_number}`,
      ];
    }

    if (userData.phone_number === '') {
      userData['phone_number'] = [];
    }

    setIsCreatingUser(true);

    try {
      await create(path, {
        ...userData,
      });

      if (page === 1) {
        void mutateUsers();
      }

      void router.push({ query: { ...router.query, page: 1 } });

      onCloseModal();
      showSuccessToast('User was added successfully');
    } catch (err) {
      const errorMessages = {};
      if (err?.name === 'HTTPError' && err.code === 422) {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          const name = err?.meta?.param_name;
          errorMessages[name] = err.message;
        });
        setError(errorMessages);
      } else {
        showErrorToast('Something went wrong, please try again');
        onCloseModal();
      }
    } finally {
      setIsCreatingUser(false);
    }
  };

  const handlePhoneChange = (val: string) => {
    if (getValues('phone_number')) {
      setCountryCodePrefix(val);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size='md'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb={4}>Create User</ModalHeader>

        <form
          autoComplete='off'
          id={FORM_ID}
          onSubmit={handleSubmit(createUser)}
        >
          <ModalBody>
            <Flex justifyContent='space-between' gap={5}>
              {first_name.enabled && (
                <InputBox
                  label='First name'
                  optional={!first_name.required}
                  error={formErrors.first_name}
                  width='100%'
                  p={0}
                  pt={2}
                >
                  <FormControl
                    isInvalid={formErrors.first_name || error?.first_name}
                  >
                    <Input
                      {...register('first_name', {
                        required: first_name.required,
                      })}
                      autoComplete='off'
                    />
                  </FormControl>

                  {(formErrors.first_name || error?.first_name) && (
                    <Text textStyle='md-normal' color='red.500' mt={1}>
                      {error?.first_name || 'First name is required'}
                    </Text>
                  )}
                </InputBox>
              )}

              {last_name.enabled && (
                <InputBox
                  label='Last name'
                  optional={!last_name.required}
                  error={formErrors.last_name}
                  width='100%'
                  p={0}
                  pt={2}
                >
                  <FormControl
                    isInvalid={formErrors.last_name || error?.last_name}
                  >
                    <Input
                      {...register('last_name', {
                        required: last_name.required,
                      })}
                      autoComplete='off'
                    />
                  </FormControl>

                  {(formErrors.last_name || error?.last_name) && (
                    <Text textStyle='md-normal' color='red.500' mt={1}>
                      {error?.last_name || 'Last name is required'}
                    </Text>
                  )}
                </InputBox>
              )}
            </Flex>

            {email_address.enabled && (
              <InputBox
                label='Email'
                optional={!email_address.required}
                error={formErrors.email_address}
                p={0}
                pt={2}
              >
                <FormControl
                  isInvalid={formErrors.email_address || error?.email_address}
                >
                  <Input
                    {...register('email_address', {
                      required: email_address.required,
                    })}
                    placeholder='name@example.com'
                    type='email'
                    autoComplete='off'
                    autoCapitalize='off'
                  />
                </FormControl>

                {(formErrors.email_address || error?.email_address) && (
                  <Text textStyle='md-normal' color='red.500' mt={1}>
                    {error?.email_address || 'Email address is required'}
                  </Text>
                )}
              </InputBox>
            )}

            {username.enabled && (
              <InputBox
                label='Username'
                optional={!username.required}
                error={formErrors.username}
                p={0}
                pt={2}
              >
                <FormControl isInvalid={formErrors.username || error?.username}>
                  <Input
                    {...register('username', {
                      required: username.required,
                    })}
                    autoComplete='off'
                    autoCapitalize='off'
                  />
                </FormControl>

                {(formErrors.username || error?.username) && (
                  <Text textStyle='md-normal' color='red.500' mt={1}>
                    {error?.username || 'Username is required'}
                  </Text>
                )}
              </InputBox>
            )}

            {phone_number.enabled && (
              <InputBox
                label='Phone number'
                optional={!phone_number.required}
                error={formErrors.phone_number}
                p={0}
                pt={2}
              >
                <PhoneInput handlePhoneChange={handlePhoneChange}>
                  <FormControl
                    isInvalid={formErrors.phone_number || error?.phone_number}
                  >
                    <Input
                      {...register('phone_number', {
                        required: phone_number.required,
                      })}
                      type='number'
                      placeholder='+(555) 555-5555'
                      autoComplete='off'
                      autoCapitalize='off'
                    />
                  </FormControl>
                </PhoneInput>

                {(formErrors.phone_number || error?.phone_number) && (
                  <Text textStyle='md-normal' color='red.500' mt={1}>
                    {error?.phone_number
                      ? `Phone number ${error?.phone_number}`
                      : 'Phone number is required'}
                  </Text>
                )}
              </InputBox>
            )}

            {password.enabled && password.required && (
              <InputBox
                label='Password'
                optional={!password.required}
                error={formErrors.password}
                p={0}
                pt={2}
              >
                <FormControl isInvalid={formErrors.password || error?.password}>
                  <Input
                    {...register('password', { required: password.required })}
                    type='password'
                    autoComplete='off'
                    autoCapitalize='off'
                  />
                </FormControl>

                {(formErrors.password || error?.password) && (
                  <Text textStyle='md-normal' color='red.500' mt={1}>
                    {error?.password || 'Password is required'}
                  </Text>
                )}
              </InputBox>
            )}
          </ModalBody>

          <ModalFooter>
            <Flex justify='space-between' w='full' flexDir='row-reverse'>
              <Button form={FORM_ID} type='submit' isDisabled={isCreatingUser}>
                Create
              </Button>
              <Button type='button' onClick={onCloseModal} variant='ghost'>
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
