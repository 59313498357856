import { transliterate } from './transliterate';

const IP_V4_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export function isIPV4Address(str: string | undefined | null): boolean {
  return IP_V4_ADDRESS_REGEX.test(str || '');
}

export function titleize(str: string | undefined | null): string {
  const s = str ?? '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/g, match =>
    match.toUpperCase().replace(/-|_/, ''),
  );
}

export function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/* eslint no-control-regex: */
const EMAIL_REGEX =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])+$/i;

export function isValidEmail(str: string | undefined | null): boolean {
  return EMAIL_REGEX.test(str || '');
}

// E.164 phone number format
const PHONE_NUMBER_REGEX = /^\+[1-9]\d{10,14}$/;

export function isPhoneNumber(str: string | undefined | null): boolean {
  return PHONE_NUMBER_REGEX.test(str || '');
}

const WEB3_WALLET_REGEX = /^0x[a-fA-F0-9]{40}$/;

export function isWeb3Wallet(str: string | undefined | null): boolean {
  return WEB3_WALLET_REGEX.test(str || '');
}

export const isValidIdentifier = (
  value: string,
  tldResult:
    | {
        domain: string;
        hostname: string;
        isPrivate: boolean;
        publicSuffix: string;
      }
    | any,
): { isValid: boolean; value: string } => {
  const { domain, hostname, isPrivate, publicSuffix } = tldResult;
  const isValidDomain = (domain || isPrivate) && !isValidEmail(value);
  const allowlistDomain = hostname || publicSuffix;

  if (
    isPhoneNumber(value) ||
    isWeb3Wallet(value) ||
    isValidEmail(value) ||
    isValidDomain
  ) {
    return {
      isValid: true,
      value: isValidDomain
        ? `*@${allowlistDomain}`
        : isValidEmail(value)
        ? value.toLowerCase()
        : value,
    };
  }

  return {
    isValid: false,
    value: '',
  };
};

export const createSlug = (str: string): string => {
  const transliterateString = transliterate(str);
  const trimmedStr = transliterateString.trim();
  const lowerCasedStr = trimmedStr.toLowerCase();
  const slug = lowerCasedStr.replace(/[^a-z0-9]+/g, '-');
  return slug;
};

export const isSlug = (str: string): boolean | string => {
  const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return slugRegex.test(str);
};
