import React from 'react';
import {
  Tooltip,
  Icon,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Link as ChakraLink,
  useClipboard,
} from '@chakra-ui/react';
import { VerticalInputBox } from '@components/common';
import {
  ClipboardCheckIcon,
  ClipboardIcon,
  ExternalLinkIcon,
} from '@heroicons/react/solid';
import { useAnalytics, useLocation } from '@hooks';

type InputPreviewProps = {
  label: string;
  value: string;
};

export function InputPreview({ label, value }: InputPreviewProps): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const { onCopy, hasCopied } = useClipboard(value);

  const copy = () => {
    onCopy();

    track(
      'Dashboard_Hosted Pages Screen_Quick Copy Copy to Clipboard Button Clicked',
      {
        surface: 'Dashboard',
        location: 'Hosted Pages Screen',
        instanceId,
      },
    );
  };

  return (
    <VerticalInputBox label={label}>
      <InputGroup>
        <Input readOnly defaultValue={value} />
        <InputRightElement width='auto'>
          <Tooltip hasArrow placement='top' label='Copy' bg='gray.900'>
            <IconButton
              size='sm'
              aria-label={hasCopied ? 'Copied' : 'Copy'}
              bg='none'
              _focus={{ bg: 'none' }}
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              onClick={copy}
            >
              <Icon
                as={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
                color='gray.300'
                boxSize={5}
              />
            </IconButton>
          </Tooltip>
          <Tooltip hasArrow placement='top' label='Visit' bg='gray.900'>
            <IconButton
              as={ChakraLink}
              href={value}
              target='_blank'
              size='sm'
              aria-label={`Visit ${label}`}
              bg='none'
              _focus={{ bg: 'none' }}
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
            >
              <Icon as={ExternalLinkIcon} color='gray.300' boxSize={5} />
            </IconButton>
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </VerticalInputBox>
  );
}
