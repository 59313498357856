import { AsyncDropdown } from '@clerk-ui/components';
import { useGetOrganizationsInfinite } from '@components/organizations/core';
import React from 'react';
import { useDebounce } from '@hooks';
import { OrganizationsAvatar } from '@components/organizations/OrganizationAvatar';
import { Flex, Text } from '@chakra-ui/react';

type SelectUserDropdownProps = {
  name?: string;
  placeholder?: string;
  userID: string;
  onSearchValueChange?: (value: string) => void;
  onOrganizationSelected: (id: string) => void;
};

const SelectOrganizationDropdown = ({
  placeholder,
  name,
  userID,
  onSearchValueChange,
  onOrganizationSelected,
}: SelectUserDropdownProps): JSX.Element => {
  const [search, setSearch] = React.useState('');
  const debounceSearchValue = useDebounce(search, 300);
  const { organizations, isLoading, loadMore, totalCount, isLoadingMore } =
    useGetOrganizationsInfinite({
      search: debounceSearchValue,
      userID,
      revalidateOnMount: true,
    });

  const onSearchChange = (value: string) => {
    setSearch(value);
    onSearchValueChange?.(value);
  };

  const options = React.useMemo(() => {
    return organizations?.map(organization => ({
      value: organization.id,
      label: (
        <Flex alignItems='center' gap={2}>
          <OrganizationsAvatar
            size='small'
            organizationsLogo={organization.logo_url}
          />
          <Text color='gray.900' textStyle='sm-medium'>
            {organization.name}
          </Text>
        </Flex>
      ),
      displayLabel: (
        <Flex ml={1} alignItems='center' gap={2}>
          <OrganizationsAvatar
            size='x-small'
            organizationsLogo={organization.logo_url}
          />
          <Text color='gray.900' textStyle='sm-normal'>
            {organization.name}
          </Text>
        </Flex>
      ),
    }));
  }, [organizations]);

  return (
    <AsyncDropdown
      inputPlaceholder={placeholder}
      searchValue={search}
      onInputChange={onSearchChange}
      name={name}
      handleChange={data => onOrganizationSelected(data.value)}
      options={options}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      infiniteScrollOptions={{
        hasDataEnd: organizations.length === totalCount,
        onLoadMore: loadMore,
      }}
    />
  );
};

export default SelectOrganizationDropdown;
