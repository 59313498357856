import React from 'react';

import { CardDualPanel } from '@components/common';
import { Stack } from '@chakra-ui/react';
import { useDashboardSWR, useInstance } from '@hooks';

import { InputPreview } from './InputPreview';

export function HostedPages(): JSX.Element {
  const { instance } = useInstance();
  const { data: displayConfig } = useDashboardSWR(() =>
    instance ? `/v1/instances/${instance?.id}/display_config` : null,
  );

  return (
    <CardDualPanel
      bg='gray.50'
      title='Hosted page URLs'
      subtitle='Visit these URLs to preview your Clerk-hosted components.'
    >
      <Stack spacing='6'>
        <InputPreview label='Sign-up URL' value={displayConfig?.sign_up_url} />
        <InputPreview label='Sign-in URL' value={displayConfig?.sign_in_url} />
        <InputPreview
          label='User profile URL'
          value={displayConfig?.user_profile_url}
        />
      </Stack>
    </CardDualPanel>
  );
}
