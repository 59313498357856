import React from 'react';

import { CardDualPanel, InputBox } from '@components/common';

import { ConfirmationModal } from '@components/modal';
import {
  Button,
  useDisclosure,
  Flex,
  Text,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Input,
} from '@chakra-ui/react';

import { useDashboardCRUD, useToast } from '@hooks';

import { ExclamationIcon } from '@heroicons/react/solid';

const ChangeSubDomainBody = ({ domain, subdomain, setSubdomain }) => {
  return (
    <Flex direction='column' gap={4}>
      <Text textStyle='md-normal'>
        Specify the subdomain where your main application lives. This will be
        used as the base for your custom redirects.
      </Text>
      <Flex direction='column' gap={1}>
        <InputBox label='Subdomain' m={0} p={0}>
          <InputGroup>
            <InputLeftAddon color='rgba(0, 0, 0, 0.65)'>
              https://
            </InputLeftAddon>

            <Input
              textAlign='right'
              value={subdomain}
              onChange={e => setSubdomain(e.target.value)}
            />
            <InputRightAddon color='rgba(0, 0, 0, 0.65)'>
              .{domain}
            </InputRightAddon>
          </InputGroup>
        </InputBox>
      </Flex>
    </Flex>
  );
};

const ChangeSubdomain = ({
  domain,
  instanceId,
  deployedOn,
  onSubdomainChange,
}: {
  domain: string;
  instanceId: string;
  deployedOn: string;
  onSubdomainChange: () => void;
}): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToast();
  const [subdomain, setSubdomain] = React.useState('');
  const { create } = useDashboardCRUD();

  async function handleChangeSubdomain() {
    try {
      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: `https://${subdomain}.${domain}`,
      });
      showSuccessToast(
        `Subdomain has been updated to https://${subdomain}.${domain}`,
      );
      onSubdomainChange();
      onClose();
    } catch (err) {
      showErrorToast('Could not update subdomain');
    }
  }

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Change application domain'
      subtitle={`Currently deployed on ${deployedOn}`}
    >
      <Button
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Change subdomain
      </Button>
      <ConfirmationModal
        alertDialogProps={{ width: '30rem' }}
        isOpen={isOpen}
        handleCancelation={onClose}
        handleConfirmation={handleChangeSubdomain}
        confirmationMessage='Change subdomain'
        cancelationMessage='Cancel'
        confirmButtonIcon={ExclamationIcon}
        message={
          <ChangeSubDomainBody
            domain={domain}
            subdomain={subdomain}
            setSubdomain={setSubdomain}
          />
        }
        headerTitle='Change application subdomain'
      />
    </CardDualPanel>
  );
};

export default React.memo(ChangeSubdomain);
